/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700|Roboto:300,400,500,600,700");
// Global vendors
@import "bootstrap/dist/css/bootstrap.css";
// => Material
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import "@angular/material/prebuilt-themes/deeppurple-amber.css";
@import "@ng-select/ng-select/themes/default.theme.css";

// Global fonts
@import "socicon/css/socicon.css";
@import "@fortawesome/fontawesome-free/css/all.min.css";
@import "./assets/plugins/flaticon/flaticon.css";
@import "./assets/plugins/flaticon2/flaticon.css";
@import "./assets/plugins/keenthemes-icons/font/ki.css";

// Metronic styles (replace these path when using RTL css below)
@import "./assets/sass/style.angular.scss";

// For RTL, check this documentation https://keenthemes.com/metronic/?page=docs&section=angular-rtl
// @import "./assets/sass/style.angular.rtl.css";

// Default Layout themes
@import "./assets/sass/themes/layout/header/base/light.scss";
@import "./assets/sass/themes/layout/header/menu/light.scss";
@import "./assets/sass/themes/layout/brand/dark.scss";
@import "./assets/sass/themes/layout/aside/dark.scss";

// Header themes
// .header-base-light {
//     @import "./assets/sass/themes/layout/header/base/light.scss";
// }

// .header-base-dark {
//     @import "./assets/sass/themes/layout/header/base/dark.scss";
// }

// Header Menu themes
// .header-menu-light {
//     @import "./assets/sass/themes/layout/header/menu/light.scss";
// }
// .header-menu-dark {
//     @import "./assets/sass/themes/layout/header/menu/dark.scss";
// }

// Brand themes
.brand-dark {
  @import "./assets/sass/themes/layout/brand/dark.scss";
}

.brand-light {
  @import "./assets/sass/themes/layout/brand/light.scss";
}

// Aside themes
// .aside-dark {
//   @import "./assets/sass/themes/layout/aside/dark.scss";
// }

// .aside-light {
//   @import "./assets/sass/themes/layout/aside/light.scss";
// }

// hide bootstrap dropdown arrow globally
.dropdown-toggle::after {
  display: none !important;
}

select.form-control{
  // --bs-form-select-bg-img: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e);
  background-color: var(--bs-body-bg);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
}

.angular-bootstrap-table {
  overflow-x: auto;
}
.angular-bootstrap-table {
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead th.sortable-active {
    color: #3699ff !important;
  }
}

.cursor-default {
  cursor: default !important;
}

.progress-modal {
  height: 3px;
  width: 100%;
}
.card-control {
  width: 100%;
}
.card-control iframe,
#iframesSubmit iframe {
  width: 100% !important;
}
.offcanvas.offcanvas-on {
  visibility: visible;
}